import { Button } from "@mui/material"
import { Box } from "@mui/system"
import homeData from "../../component/data"
import DropzoneComponent from "../../component/DropzoneComponent"
import { useEffect, useRef, useState } from "react"
import "../styles/home.scss"
import { ViewPdf } from "../../component/Comps"
import { useNavigate } from "react-router-dom"
export const Home = () => {
    const [isPc, setIsPc] = useState(true);
    const mainRef = useRef<null | HTMLDivElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        getWidth();
    }, [])
    const getWidth = () => {
        let width = document.body.clientWidth;
        if (width < 1200) {
            setIsPc(false);
        }
    }

    const handleClick = () =>{
        if (mainRef && mainRef.current) {
            mainRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }

    return (<div className="home">
        <div className="home-line" />
        <div className="home-content">
            <div className="content-top">
                <hgroup>
                    <h1>BILL READER</h1>
                    <h4>Provides unprecedented capability to capture customer bill data via smart
                        phone photo scan or file upload.<a>Powered by machine learning.</a>
                    </h4>
                    <div className="pc-buttons">
                        <Button variant="contained"  onClick={()=>{handleClick()}}>Schedule an online demo</Button>
                        <Button variant="contained" onClick={() => { navigate('/contact') }}>Contact Us</Button>
                    </div>
                </hgroup>
                <img className="pc-img" src={require("../images/bill.png")} alt="" />
            </div>
            <div className="mobile-buttons">
                <Button variant="contained"  >Schedule an online demo</Button>
                <Button variant="contained" onClick={() => { navigate('/contact') }}>Contact Us</Button>
            </div>
            <ul className="icons">
                <li>
                    <div className="top">
                        <img src={require("../images/icon1.png")} alt="" />
                        <span>Plug and play</span></div>
                    <label>{homeData.plug_label}</label>
                </li>
                <li>
                    <div className="top">
                        <img src={require("../images/icon2.png")} alt="" />
                        <span>Reduce errors</span></div>
                    <label>{homeData.reduce_label}</label>
                </li>
                <li>
                    <div className="top">
                        <img src={require("../images/icon3.png")} alt="" />
                        <span>Full technology</span></div>
                    <label>{homeData.full_label}</label>
                </li>
                <li>
                    <div className="top">
                        <img src={require("../images/icon4.png")} alt="" />
                        <span>Fast</span>
                    </div>
                    <label>{homeData.fast_label}</label>
                </li>

                {!isPc && <div style={{ clear: 'both' }} />}
            </ul>
            <div className="content-pc" ref={mainRef}>
                <h1 className="content-pc-h1">Test  your own bills, Now!</h1>
                <label>Toggle between the demo images or upload your own and extract data live.</label>
                <div className="upload">
                    <DropzoneComponent />
                </div>
                <Box paddingY={5} textAlign="end" width="90vw">
                    <Button variant="contained" className="contact" onClick={() => { navigate('/contact') }} >Contact Us</Button>
                </Box>
            </div>


            <div className="mobile_upload"> <DropzoneComponent /> </div>
            <ul className="bottom">
                <li>Why choose Billreader?</li>
                <li> <span>{homeData.footer_label1}</span><img src={require("../images/bill_icon1.png")} alt="" /></li>
                <li><img src={require("../images/bill_icon2.png")} alt="" /> <span>{homeData.footer_label2}</span></li>
                <li><span>{homeData.footer_label3}</span><img src={require("../images/bill_icon3.png")} alt="" /> </li>
                <li><img src={require("../images/bill_icon4.png")} alt="" /> <span>{homeData.footer_label4}</span></li>
            </ul>
        </div>
    </div>)
}