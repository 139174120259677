import { memo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

const getTable = (props: any) => {
    let { addrs, meter_id, esiid } = props || {};
    let address: any = [];
    let addr: any = [];
    let esi_id = '';
    if (esiid && esiid.length != 0) {
        esi_id = Object.keys(esiid)[0];
    }

    let meterId = '';
    if (meter_id && meter_id.length != 0) {
        meterId = Object.keys(meter_id)[0];
    }

    if (addrs && addrs.length != 0) {
        addrs.forEach((element: any) => {
            if (!("po_box" in element)) {
                let { city, house_number, postcode, road, state } = element;
                var a = `${house_number} ${road},${city},${state} ${postcode}`;
                address.push(a);
                addr.push(element);
            }
        });
    }
    return { address, meterId, addr, esi_id };
}

const isEmail = (str: string) => {
    var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    return reg.test(str);
}

const ViewPdf = memo((props: any) => {
    const [pages, setPages] = useState(0)

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cat.net/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

    let { url } = props;

    const onLoadSuccess = ({ numPages }: any) => {
        setPages(numPages)
    }

    return (
        <div style={{ height: 480, width: "100%", overflowX: "hidden" }}>
            <Document file={{ url: url }} onLoadSuccess={onLoadSuccess}>
                {new Array(pages).fill(' ').map((e, i) => {
                    return (
                        <Page
                            className='view-page'
                            key={i}
                            pageNumber={i + 1}
                            renderTextLayer={false}
                            width={360}
                            height={600}
                            customTextRenderer={undefined}
                        />
                    )
                })}
            </Document>
        </div>
    )

})
export { getTable, isEmail, ViewPdf };