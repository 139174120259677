import React, { useState, useEffect, useMemo, useRef } from "react";
import showdown from "showdown";

export const TypingComp = ({ text, speed = 50, mainRef, type }: any) => {
  const [defaultText, setDefaultText] = useState("");
  const [displayedText, setDisplayedText] = useState("");
  const [isDone, setIsDone] = useState(false);

  const converter = useMemo(
    () =>
      new showdown.Converter({
        disableForced4SpacesIndentedSublists: true,
      }),
    []
  );
  useEffect(() => {
    const typed = sessionStorage.getItem("typed");
    const showText = converter.makeHtml(text);
    setDefaultText(showText)
    if (typed === "true") return setIsDone(true);
    if (isDone) return;
    const words = showText?.trim()?.split(" ") || [];
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      if (currentIndex < words.length - 1) {
        if(currentIndex === 0){
          setDisplayedText(words[currentIndex]+ " ")
        }else {
          setDisplayedText((prev) => prev + words[currentIndex] + " ");
        }
        currentIndex ++;
      } else {
        clearInterval(intervalId);
        setIsDone(true);
        sessionStorage.setItem("typed", "true");
      }
    }, speed);

    return () => clearInterval(intervalId);
  }, [text, speed]);
  

  useEffect(() => {
    if (isDone) return;
    const element = document.querySelector('.home .tabs');
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [displayedText]);

  if (isDone) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: defaultText }}
        style={{
          whiteSpace: "pre-wrap",
          display: "flex",
          flexDirection: "column",
        }}
      />
    );
  }
  return (
    <div
      dangerouslySetInnerHTML={{ __html: displayedText }}
      style={{
        whiteSpace: "pre-wrap",
        display: "flex",
        flexDirection: "column",
        height:'550px'
      }}
    />
  );
};
